import React from 'react';
import tw, { styled } from 'twin.macro';

const Button = styled.button(({ isActive }) => [
  tw`font-semibold border border-solid border-white-50 rounded-full py-1 px-2 mr-2 mb-3 text-xs sm:(mr-4 py-2 px-5 mb-0) hover:border-white`,
  isActive && tw`bg-white text-blue-dark`,
]);

const SelectButton = ({ label, onClick, isActive, ...rest }) => (
  <Button onClick={onClick} isActive={isActive} {...rest}>
    {label}
  </Button>
);

export default SelectButton;
