import React from 'react';
import tw, { css } from 'twin.macro';
import { useFilterContext } from 'contexts/filter';
import SelectButton from 'components/SelectButton';
import JobRoleIcon from 'components/Icons/JobRoleIcon';

const funnelRolesStyles = css`
  ${tw`grid grid-cols-4 xl:grid-cols-8`}
  li {
    ${tw`flex flex-col items-center justify-end text-center`}
    button {
      ${tw`text-xxs sm:text-xs mt-2 mr-0`}
    }
    &:last-of-type button {
      ${tw`mr-0`}
    }
    svg {
      ${tw`w-full h-auto w-4/5`}
    }
  }
`;

const Filter = ({ showAll, inFunnel, withIcons, ...rest }) => {
  const { categories, activeFilters, roleFilters, handleFilter } = useFilterContext();
  const from = inFunnel ? 'jobsfilter' : 'mainfilter';
  const arr = from === 'jobsfilter' ? roleFilters : activeFilters;

  return (
    <ul tw="mt-3 sm:mt-6 flex flex-wrap" css={withIcons && funnelRolesStyles} {...rest}>
      {showAll && (
        <li>
          <SelectButton
            label="Show All"
            isActive={activeFilters?.length === 0}
            onClick={() => handleFilter(from, 'Show All')}
          />
        </li>
      )}
      {categories?.map(category => (
        <li key={category.label} tw="sm:mb-3">
          {withIcons && JobRoleIcon(category.icon)}
          <SelectButton
            label={category.label}
            isActive={arr?.includes(category)}
            onClick={() => handleFilter(from, category)}
          />
        </li>
      ))}
    </ul>
  );
};

export default Filter;
