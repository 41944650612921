import React, { useEffect, useRef } from 'react';
import tw, { css } from 'twin.macro';
import VanillaTilt from 'vanilla-tilt';
import Link from 'components/Link';
import { useLocation } from '@reach/router';
import { ArrowIcon } from 'components/Icons';

const reverseStyles = css`
  ${tw`flex flex-row-reverse`}
  svg {
    transform: rotate(180deg);
    ${tw`ml-0 mr-7`}
  }
`;
const hideStyles = css`
  visibility: hidden !important;
  opacity: 0;
`;

const disabledStyles = css`
  opacity: 0.25;
`;

const buttonStyles = css`
  background: #ffffff10;
  box-shadow: 0px 10px 10px #00000025;
  ${tw`inline-block`}

  @media only screen and (max-width: 768px) {
    transform: none !important;
  }

  a,
  button {
    ${tw`block py-5 px-14 font-semibold flex justify-between items-center w-full border border-solid border-white cursor-pointer outline-none focus:(border-green)`}
  }
  svg {
    ${tw`ml-7`}
  }
`;

const newStyles = css`
  span {
    ${tw`text-lg uppercase font-bold`}
  }
`;

const TiltButton = ({
  onClick,
  label,
  accent,
  disabled,
  hide,
  reverse,
  to,
  arrowDirection,
  arrow,
  external,
  ...rest
}) => {
  const tiltRef = useRef();
  const { href } = useLocation();

  useEffect(() => {
    const tiltOptions = {
      max: 12,
      speed: 1800,
      reverse: false,
      glare: true,
      'max-glare': 0.5,
      gyroscope: false,
    };

    VanillaTilt.init(tiltRef.current, tiltOptions);
  }, []);

  const route =
    process.env.NODE_ENV === 'development'
      ? to?.replace('https://talentedindenmark.dk/', '/')
      : to?.replace(href, '/') || null;

  return (
    <div
      css={[buttonStyles, disabled && disabledStyles, hide && hideStyles, newStyles]}
      ref={tiltRef}
      {...rest}
    >
      {onClick ? (
        <button
          onClick={onClick}
          css={reverse ? reverseStyles : undefined}
          disabled={disabled || hide}
          tabIndex="0"
          type="button"
        >
          <span>{label}</span>
          {arrow && <ArrowIcon accent="red" arrowDirection={arrowDirection} />}
        </button>
      ) : (
        <Link
          to={route}
          css={[reverse && reverseStyles, hide && hideStyles]}
          disabled={hide}
          tabIndex="0"
          target={external ? '_blank' : '_self'}
        >
          <span>{label}</span>
          {arrow && <ArrowIcon accent="white" arrowDirection={arrowDirection} />}
        </Link>
      )}
    </div>
  );
};

export default TiltButton;
