import React from 'react';

export const Commercial = () => (
  <svg
    id="commercial"
    dataname="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 180 180"
  >
    <defs>
      <linearGradient
        id="New_Gradient_Swatch_4"
        x1=".04"
        y1="149.34"
        x2="177.43"
        y2="149.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5ec2bb" />
        <stop offset="1" stopColor="#203075" />
      </linearGradient>
      <linearGradient
        id="linear-gradient"
        x1="137.27"
        y1="108.81"
        x2="172.37"
        y2="108.81"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5ec2bb" />
        <stop offset=".57" stopColor="#203075" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_4-2"
        x1="111.62"
        y1="100.2"
        x2="127.92"
        y2="100.2"
        xlinkHref="#New_Gradient_Swatch_4"
      />
      <linearGradient
        id="New_Gradient_Swatch_4-3"
        x1="78.81"
        y1="111.05"
        x2="108.03"
        y2="111.05"
        xlinkHref="#New_Gradient_Swatch_4"
      />
      <linearGradient
        id="New_Gradient_Swatch_4-4"
        x1="48.99"
        y1="130.09"
        x2="78.52"
        y2="130.09"
        xlinkHref="#New_Gradient_Swatch_4"
      />
    </defs>
    <path
      opacity=".38"
      fill="url(#New_Gradient_Swatch_4)"
      d="M177.43 154.3l-70.3 18.98L.04 144.37l70.3-18.98 107.09 28.91z"
    />
    <path
      fill="url(#linear-gradient)"
      opacity=".8"
      d="M153.06 132.54l-16.3-4.36V85.09l16.3 4.36v43.09z"
    />
    <path className="cls-3" d="M153.06 89.49l-16.3-4.4 16.3-4.4 16.3 4.4-16.3 4.4z" />
    <path className="cls-4" d="M169.35 128.18l-16.29 4.36V89.45l16.29-4.36v43.09z" />
    <path
      fill="url(#New_Gradient_Swatch_4-2)"
      opacity=".8"
      d="M127.92 140.33l-16.3-4.37V60.07l16.3 4.37v75.89z"
    />
    <path className="cls-3" d="M127.92 64.47l-16.3-4.4 16.3-4.4 16.29 4.4-16.29 4.4z" />
    <path className="cls-4" d="M144.21 135.96l-16.29 4.37V64.44l16.29-4.37v75.89z" />
    <path
      fill="url(#New_Gradient_Swatch_4-3)"
      opacity=".8"
      d="M98.43 149.48l-19.62-5.26v-71.6l19.62 5.26v71.6z"
    />
    <path className="cls-3" d="M98.43 77.92l-19.62-5.3 19.62-5.29 19.61 5.29-19.61 5.3z" />
    <path className="cls-4" d="M118.04 144.22l-19.61 5.26v-71.6l19.61-5.26v71.6z" />
    <path
      d="M16.19 74.87c2.17-1.39 4.39-2.89 6.69-4.25a5.69 5.69 0 012.6-.77c2.82-.09 5.66-.1 8.48 0A9.33 9.33 0 0043.71 60a11.62 11.62 0 010-1.18 2.35 2.35 0 012.45-2.31 2.39 2.39 0 012.46 2.32c.23 5.91-1.76 10.72-6.92 14a1.54 1.54 0 00-.53 1.12c-.1 3 .08 6.11-.26 9.11a25.79 25.79 0 01-12.4 19.71 1.63 1.63 0 00-.91 1.6c.05 3.54 0 7.08 0 10.7h44v-1.18-45.86c0-11.42-4.73-20.57-14-27.24a30.16 30.16 0 00-16.12-5.69c-.65 0-1.29-.11-1.93-.15-2-.1-2.85-.94-2.84-3a8.27 8.27 0 00-1.38-5 7.17 7.17 0 00-3.72-2.79v11.08a4.76 4.76 0 01-1.06 3.16Q19.7 52.59 8.88 66.75c-.13.17-.25.34-.33.46zm66.09 52.31c-.24-4.16-4.12-7.09-8.81-7.09H24.32c-4.46 0-7.43 2.11-8.73 6.2a7.91 7.91 0 00-.35 2.32v11.81a4 4 0 00.06.55h67.04c0-4.65.21-9.24-.06-13.79z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="1.88"
    />
    <path
      fill="url(#New_Gradient_Swatch_4-4)"
      d="M68.6 158.65l-19.61-5.25v-51.87l19.61 5.26v51.86z"
    />
    <path className="cls-3" d="M68.6 106.83l-19.61-5.3 19.61-5.29 19.62 5.29-19.62 5.3z" />
    <path className="cls-4" d="M88.22 153.4l-19.62 5.25v-51.86l19.62-5.26v51.87z" />
  </svg>
);
export const DataScientist = () => (
  <svg
    id="datascientist"
    dataname="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 180 180"
  >
    <defs>
      <linearGradient
        id="datascientist_swatch"
        x1="8.08"
        y1="141.45"
        x2="170.33"
        y2="141.45"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5ec2bb" />
        <stop offset="1" stopColor="#203075" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_4-2"
        x1="40.86"
        y1="102.33"
        x2="136.85"
        y2="102.33"
        xlinkHref="#datascientist_swatch"
      />
      <linearGradient
        id="New_Gradient_Swatch_4-3"
        x1="40.86"
        y1="66.61"
        x2="136.85"
        y2="66.61"
        xlinkHref="#datascientist_swatch"
      />
    </defs>
    <ellipse
      cx="89.2"
      cy="141.45"
      rx="81.12"
      ry="21.9"
      opacity=".38"
      fill="url(#New_Gradient_Swatch_4)"
    />
    <path className="cls-2" d="M67.34 143.25l-15.59 4.13 8.69 2.45-20.46 5.41" />
    <path
      className="cls-2"
      d="M73.61 145.02l-8.76 2.32 8.68 2.45L46.25 157M54.56 139.65l-15.59 4.12-8.69-2.45-20.46 5.41"
    />
    <path
      className="cls-2"
      d="M48.34 137.87l-8.82 2.33-8.69-2.45-27.28 7.21M60.7 141.38l-36.05 9.53"
    />
    <g opacity=".56">
      <path className="cls-4" d="M40.86 101.26v1.15a3.5 3.5 0 01.19-1.14z" />
      <path
        className="cls-4"
        d="M122.79 111.57c-18.74 5.06-49.13 5.06-67.87 0-9.38-2.53-14.06-5.85-14.06-9.16v30.35c0 3.32 4.68 6.63 14.06 9.16 18.74 5.07 49.13 5.07 67.87 0 9.38-2.53 14.06-5.84 14.06-9.16v-30.35c0 3.31-4.68 6.59-14.06 9.16z"
      />
    </g>
    <ellipse className="cls-5" cx="88.86" cy="132.54" rx="48" ry="12.96" />
    <ellipse
      cx="88.86"
      cy="102.33"
      rx="48"
      ry="12.96"
      fill="url(#New_Gradient_Swatch_4-2)"
      opacity=".56"
    />
    <ellipse
      cx="88.86"
      cy="66.61"
      rx="48"
      ry="12.96"
      fill="url(#New_Gradient_Swatch_4-3)"
      opacity=".56"
    />
    <ellipse
      className="cls-8"
      cx="119.96"
      cy="65.68"
      rx="15.59"
      ry="50.43"
      transform="rotate(-1.89 119.89678847 65.65120446)"
    />
    <ellipse
      className="cls-8"
      cx="119.96"
      cy="65.68"
      rx="14.89"
      ry="52.79"
      transform="rotate(-44.57 119.96627586 65.6802133)"
    />
    <ellipse
      className="cls-8"
      cx="119.96"
      cy="65.68"
      rx="40.18"
      ry="19.57"
      transform="rotate(-16.65 119.9758843 65.68456624)"
    />
    <ellipse
      className="cls-5"
      cx="119.84"
      cy="65.06"
      rx="2.45"
      ry="3.37"
      transform="rotate(-27.84 119.84902268 65.0570459)"
    />
  </svg>
);

export const CoFounder = () => (
  <svg
    id="cofounder"
    dataname="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 180 180"
  >
    <defs>
      <linearGradient
        id="New_Gradient_Swatch_4"
        x1="63.96"
        y1="114.37"
        x2="168.86"
        y2="114.37"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5ec2bb" />
        <stop offset="1" stopColor="#203075" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_4-2"
        x1="63.99"
        y1="114.55"
        x2="132.22"
        y2="114.55"
        xlinkHref="#New_Gradient_Swatch_4"
      />
      <linearGradient
        id="New_Gradient_Swatch_4-3"
        x1="13.52"
        y1="118.82"
        x2="146.55"
        y2="118.82"
        xlinkHref="#New_Gradient_Swatch_4"
      />
      <linearGradient
        id="New_Gradient_Swatch_4-4"
        x1="13.55"
        y1="119.04"
        x2="100.09"
        y2="119.04"
        xlinkHref="#New_Gradient_Swatch_4"
      />
    </defs>
    <path
      fill="url(#New_Gradient_Swatch_4)"
      d="M132.11 155.7l36.75-10.65-53.33-72.01-51.57 63.35"
    />
    <path fill="url(#New_Gradient_Swatch_4-2)" d="M63.99 136.4l68.23 19.5-16.75-82.7-51.48 63.2z" />
    <path
      fill="url(#New_Gradient_Swatch_4-3)"
      d="M99.95 171.24l46.6-13.51L78.92 66.4l-65.4 80.35"
    />
    <path
      fill="url(#New_Gradient_Swatch_4-4)"
      d="M13.54 146.76l86.55 24.73L78.84 66.6l-65.3 80.16z"
    />
    <path
      className="cls-5"
      d="M36.27 118.27l13.55-8.45L52.25 120l10.43-10.57 2.9 16.73 8-9.22 3.16 12.61 7.75-9.21 7.6 12.04-13.17-65.94-42.65 51.83z"
    />
    <path
      className="cls-5"
      d="M78.92 66.44l41.21 56.08-9.21-6.3 1.45 8.72-6.06-8.24-.24 9.46-6.06-6.79-.73 9.21-6.79-8.24-.4 11.96-13.17-65.86z"
    />
    <path
      className="cls-6"
      d="M79 52.61c-15.7-12.61-31.36 12.6-47 0V24c15.65 12.61 31.31-12.6 47 0zM78.93 16.97v84.19"
    />
  </svg>
);

export const Designer = () => (
  <svg id="designer" dataname="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 180">
    <defs>
      <linearGradient
        id="New_Gradient_Swatch_4"
        x1="73.51"
        y1="77.15"
        x2="165.4"
        y2="77.15"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5ec2bb" />
        <stop offset="1" stopColor="#203075" />
      </linearGradient>
    </defs>
    <path
      d="M137.93 129.51a7.23 7.23 0 00.15-1.4c0-6.35-8.34-11.51-18.62-11.51s-18.63 5.16-18.63 11.51a7.23 7.23 0 00.15 1.4 6.56 6.56 0 000 2.79 6.56 6.56 0 000 2.79 6.56 6.56 0 000 2.79 6.56 6.56 0 000 2.79 7.31 7.31 0 00-.15 1.4 7.13 7.13 0 00.15 1.39 7.31 7.31 0 00-.15 1.4c0 4.72 4.61 8.78 11.2 10.55v.36a7.45 7.45 0 0014.89 0v-.36c6.59-1.77 11.2-5.83 11.2-10.55a7.31 7.31 0 00-.15-1.4 7.13 7.13 0 00.15-1.39 7.31 7.31 0 00-.15-1.4 6.56 6.56 0 000-2.79 6.56 6.56 0 000-2.79 6.56 6.56 0 000-2.79 6.56 6.56 0 000-2.79z"
      fill="#5ec2bb"
    />
    <path
      d="M165.4 65.19A45.95 45.95 0 1088.71 99.3a33.84 33.84 0 017 15.57 7.56 7.56 0 00.38 1.42 24 24 0 0046.71.27 6.1 6.1 0 00.5-1.69 33.83 33.83 0 017.18-15.81 45.76 45.76 0 0014.92-33.87z"
      fill="url(#New_Gradient_Swatch_4)"
    />
    <ellipse cx="119.3" cy="123.65" rx="18.62" ry="11.51" fill="#203075" opacity=".58" />
    <path
      stroke="#203075"
      strokeLinejoin="round"
      strokeWidth="3"
      fill="none"
      d="M112.4 118.83V87.12L99.25 51.58l39.92 12.82-13.15 27.09v31.71"
    />
    <path
      className="cls-5"
      d="M54.6 159.33l-.83-88.73-10.18-14.33-10.06 14.52.84 88.73 20.23-.19zM54.75 152.39l-20.23.19M33.53 70.79l20.24-.19M47.81 152.45l-.76-80.92M40.59 152.52l-.76-80.93M46.07 59.39l-4.62.05"
    />
    <path
      d="M43.47 58.2A19.69 19.69 0 0129.6 40.87a19.69 19.69 0 0111.79-18.81c4.61-1.9 10.42-1.7 13.77 1.94 3.84 4.12 2.9 10.7.84 15.93-1 2.43-2.27 5-4.62 6.09-3.14 1.52-7.15-.16-9.08-3.06s-2.14-6.65-1.57-10.09c1.09-6.48 5.13-12.72 11.27-15.11s14.12.09 16.73 6.12c2.3 5.29.15 11.45-2.82 16.39s-6.84 9.51-8.33 15.08a10.42 10.42 0 00.23 7.08c1.25 2.68 4.09 4.33 7 4.9 7.63 1.51 15.12-3.48 20.11-9.44S93.42 44.77 99 39.37s14.41-8.74 21.19-4.93"
      strokeLinecap="round"
      stroke="#fff"
      strokeMiterlimit="10"
      strokeWidth="2"
      fill="none"
    />
  </svg>
);

export const Developer = () => (
  <svg
    id="developer"
    dataname="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 180 180"
  >
    <defs>
      <linearGradient
        id="New_Gradient_Swatch_4"
        x1="63.05"
        y1="142.08"
        x2="94.41"
        y2="142.08"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5ec2bb" />
        <stop offset="1" stopColor="#203075" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_4-2"
        x1="16.83"
        y1="90.2"
        x2="150.58"
        y2="90.2"
        xlinkHref="#New_Gradient_Swatch_4"
      />
      <linearGradient
        id="linear-gradient"
        x1="135.45"
        y1="101.3"
        x2="12.76"
        y2="68.25"
        xlinkHref="#New_Gradient_Swatch_4"
      />
    </defs>
    <path
      d="M89 166.35l-52-13.59a.76.76 0 01.08-1.48l30.39-4.47a.81.81 0 01.31 0l52 13.59a.76.76 0 01-.08 1.48l-30.39 4.47a.76.76 0 01-.31 0z"
      fill="#5ec2bb"
    />
    <path
      fill="url(#New_Gradient_Swatch_4)"
      d="M65.86 123.82l-.06 2.87-2.25-.56-.5 26.22 28.57 7.99 2.3-2.31.49-27-28.55-7.21z"
    />
    <path
      d="M146 154.34L19.75 120.52a4.2 4.2 0 01-2.92-4l1.56-88.11a2.33 2.33 0 013-2.37l126.27 33.85a4.19 4.19 0 012.92 4L149 152a2.54 2.54 0 01-.92 2 2.34 2.34 0 01-2.08.34z"
      fill="url(#New_Gradient_Swatch_4-2)"
    />
    <path
      fill="url(#linear-gradient)"
      d="M145.12 144.23L19.49 110.58l1.43-80.46 125.63 33.66-1.43 80.45z"
    />
    <path
      className="cls-5"
      d="M58.01 79.98l-12.04 27.75M38.5 80.07l-9.04 6.83 8.74 12.23M65.24 106.54l9.04-6.83-8.74-12.23"
    />
    <path
      className="cls-6"
      d="M101.21 76.4l8.57 8.41a25.91 25.91 0 012.53-2.22 7.82 7.82 0 018.68-.37 7.74 7.74 0 014.08 7.16v2.16h12.13c-.38-4.27.59-7.87 4.88-9.74s7.58-.14 10.49 3.15l8.43-8.79a25.71 25.71 0 01-1.78-2 8.15 8.15 0 015.42-13.15c1-.12 2-.12 3.16-.19v-12a.34.34 0 00-.14 0h-1.89c-3.54-.13-6.27-1.63-7.67-5a8 8 0 011.63-8.82l1.33-1.46-8.68-8.54c-.58.53-1.2 1.17-1.9 1.71a8.13 8.13 0 01-13.2-5.29c-.13-1-.12-2.1-.18-3.2h-12v2.34a7.7 7.7 0 01-5 7.44 7.69 7.69 0 01-8.62-1.4c-.6-.52-1.16-1.09-1.69-1.59l-8.52 8.57c.53.59 1.14 1.19 1.67 1.87a7.8 7.8 0 011.24 8.22 7.77 7.77 0 01-6.6 5.07c-1 .12-2 .1-3.09.15v12c1.13.06 2.21.06 3.28.18a8.06 8.06 0 016 12.11 31.51 31.51 0 01-2.56 3.22z"
    />
    <path
      className="cls-6"
      d="M150.64 54.9a19.51 19.51 0 00-19.52-19.49 19.48 19.48 0 1019.52 19.49z"
    />
  </svg>
);

export const Engineer = () => (
  <svg
    id="engineer"
    dataname="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 180 180"
  >
    <defs>
      <linearGradient
        id="New_Gradient_Swatch_4"
        x1="21.4"
        y1="122.67"
        x2="118.61"
        y2="122.67"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5ec2bb" />
        <stop offset="1" stopColor="#203075" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_4-2"
        x1="118.51"
        y1="142.18"
        x2="167.52"
        y2="142.18"
        xlinkHref="#New_Gradient_Swatch_4"
      />
      <linearGradient
        id="New_Gradient_Swatch_4-3"
        x1="70.21"
        y1="96.68"
        x2="119.02"
        y2="96.68"
        xlinkHref="#New_Gradient_Swatch_4"
      />
      <linearGradient
        id="New_Gradient_Swatch_4-4"
        x1="21.4"
        y1="83.73"
        x2="118.61"
        y2="83.73"
        xlinkHref="#New_Gradient_Swatch_4"
      />
      <linearGradient
        id="New_Gradient_Swatch_4-5"
        x1="70.11"
        y1="122.56"
        x2="167.42"
        y2="122.56"
        xlinkHref="#New_Gradient_Swatch_4"
      />
    </defs>
    <path
      fill="url(#New_Gradient_Swatch_4)"
      d="M21.6 135.81l97.01 25.99-.1-26.3-48.2-12.53V96.68L21.4 83.53l.2 52.28z"
    />
    <path
      d="M118.71 162l48.81-13.15v-26.49l-49 13.25s.4 26.39.19 26.39z"
      fill="url(#New_Gradient_Swatch_4-2)"
    />
    <path fill="url(#New_Gradient_Swatch_4-3)" d="M119.02 109.72V83.64L70.21 96.58" />
    <path
      fill="url(#New_Gradient_Swatch_4-4)"
      d="M21.4 83.73l48.81-13.25 48.4 13.45-48.3 13.05L21.4 83.73z"
    />
    <path
      fill="url(#New_Gradient_Swatch_4-5)"
      d="M70.11 122.77l48.6-13.35 48.71 12.94-48.71 13.35-48.6-12.94z"
    />
    <path
      className="cls-6"
      d="M148.74 32.79v130.6M141.2 32.79h15.07M141.2 65.44h15.07M141.2 98.09h15.07M141.2 130.74h15.07M141.2 163.39h15.07"
    />
    <path fill="#fff" d="M21.4 58.32l48.81-13.25 48.4 13.45-48.3 13.04L21.4 58.32z" />
  </svg>
);

export const Marketing = () => (
  <svg
    id="marketing"
    dataname="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 180 180"
  >
    <defs>
      <linearGradient
        id="New_Gradient_Swatch_4"
        x1="49.35"
        y1="90.84"
        x2="61.07"
        y2="90.84"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5ec2bb" />
        <stop offset="1" stopColor="#203075" />
      </linearGradient>
      <linearGradient
        id="linear-gradient"
        x1="44.74"
        y1="52.36"
        x2="150.35"
        y2="103.47"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset=".05" stopColor="#5ec2bb" />
        <stop offset=".67" stopColor="#203075" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_4-2"
        x1="102.23"
        y1="94.17"
        x2="162.68"
        y2="94.17"
        gradientTransform="rotate(66.3 132.45695972 94.16549433)"
        xlinkHref="#New_Gradient_Swatch_4"
      />
      <linearGradient
        id="New_Gradient_Swatch_4-3"
        x1="102.94"
        y1="86.04"
        x2="138.41"
        y2="86.04"
        gradientTransform="rotate(-1.63 118.68609244 83.95228924)"
        xlinkHref="#New_Gradient_Swatch_4"
      />
    </defs>
    <path fill="url(#New_Gradient_Swatch_4)" d="M49.34 71.3v35.72l11.73 3.35V78l-11.73-6.7z" />
    <path d="M66.09 105.35l-5 5V72l5.16 11s-.44 22.07-.16 22.35z" fill="#203075" />
    <path
      d="M50.88 73.26L67 38.33l-9.31-8.44A10.19 10.19 0 0055 27.47a9.47 9.47 0 00-4.91-1.37c-8.35-.1-17.44 9.42-20.32 21.21-2.08 8.5-.34 15.86 3.91 19.33l.54.44a9.32 9.32 0 004.36 1.61z"
      fill="#5ec2bb"
    />
    <path
      d="M141.31 47.33h-.89l-71.65-9.28a9.46 9.46 0 00-2-.26c-7.65-.07-16 8.66-18.65 19.49-2 8.14-.2 15.14 4.06 18.16l50.76 57.1c.22.27.44.52.68.77l.44.5a20.3 20.3 0 0014.63 6.14c18.06.16 37.77-20.43 44-46s-3.32-46.46-21.38-46.62z"
      fill="url(#linear-gradient)"
    />
    <ellipse
      cx="132.45"
      cy="94.17"
      rx="45.23"
      ry="26.36"
      transform="rotate(-66.3 132.45442815 94.16641662)"
      fill="url(#New_Gradient_Swatch_4-2)"
    />
    <path
      d="M103.92 96.91c-.2.89-.38 1.77-.55 2.64l28 4.3 7-20.08-20.75-15.85a74.85 74.85 0 00-13.7 28.99z"
      fill="url(#New_Gradient_Swatch_4-3)"
    />
    <ellipse
      cx="133.79"
      cy="93.32"
      rx="11.06"
      ry="6.45"
      transform="rotate(-67.93 133.77670073 93.31950667)"
      fill="#fff"
    />
    <path className="cls-8" d="M18.93 119.98h64.74v43.53H18.93z" />
    <path className="cls-8" d="M18.93 119.98l32.37 21.77 32.37-21.77" />
    <path
      className="cls-8"
      transform="rotate(22.26 69.15568899 76.3076995)"
      d="M43.95 59.37h50.43v33.91H43.95z"
    />
    <path className="cls-8" d="M52.25 51.09l16.91 25.24 29.76-6.15" />
  </svg>
);

export const Sales = () => (
  <svg
    id="sales"
    dataname="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 180 180"
  >
    <defs>
      <linearGradient
        id="New_Gradient_Swatch_4"
        x1="59.47"
        y1="97.27"
        x2="172.21"
        y2="97.27"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#5ec2bb" />
        <stop offset="1" stopColor="#203075" />
      </linearGradient>
      <linearGradient
        id="New_Gradient_Swatch_4-2"
        x1="42.3"
        y1="96.09"
        x2="154.55"
        y2="96.09"
        gradientTransform="rotate(36.18 98.42307914 96.10100636)"
        xlinkHref="#New_Gradient_Swatch_4"
      />
    </defs>
    <path
      opacity=".38"
      fill="url(#New_Gradient_Swatch_4)"
      d="M172.21 53.58L59.47 23.37v117.6l112.74 30.21V53.58z"
    />
    <ellipse
      cx="98.42"
      cy="96.09"
      rx="51.94"
      ry="63.22"
      transform="rotate(-36.18 98.43451694 96.10228425)"
      fill="url(#New_Gradient_Swatch_4-2)"
    />
    <ellipse
      className="cls-3"
      cx="90.96"
      cy="98.58"
      rx="51.94"
      ry="63.22"
      transform="rotate(-36.18 90.96403676 98.59149477)"
    />
    <ellipse
      className="cls-4"
      cx="90.96"
      cy="98.58"
      rx="39.52"
      ry="48.1"
      transform="rotate(-35.9 91.54997397 98.39744925)"
    />
    <ellipse
      className="cls-3"
      cx="90.96"
      cy="98.58"
      rx="27.6"
      ry="33.6"
      transform="rotate(-36.18 90.96403676 98.59149477)"
    />
    <ellipse
      className="cls-4"
      cx="90.96"
      cy="98.58"
      rx="15.55"
      ry="18.93"
      transform="rotate(-36.18 90.96403676 98.59149477)"
    />
    <ellipse
      className="cls-3"
      cx="90.96"
      cy="98.58"
      rx="6.58"
      ry="8"
      transform="rotate(-36.18 90.96403676 98.59149477)"
    />
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
      stroke="#fff"
      strokeWidth="2"
      d="M91.3 98.41L27.87 76.98"
    />
    <path
      className="cls-6"
      d="M39.07 81.06l15.74 5.34-18.78 8.43-15.73-5.34 18.77-8.43zM28.05 77.32l6.36 2.16-18.77 8.43-6.36-2.16 18.77-8.43zM39.43 80.91l15.73 5.34-9.76-18.12-15.74-5.34 9.77 18.12zM28.41 77.17l6.36 2.16L25 61.21l-6.36-2.16 9.77 18.12z"
    />
  </svg>
);

const JobRole = id => {
  switch (id) {
    case 'cofounder':
      return <CoFounder />;
    case 'commercial':
      return <Commercial />;
    case 'datascientist':
      return <DataScientist />;
    case 'designer':
      return <Designer />;
    case 'developer':
      return <Developer />;
    case 'engineer':
      return <Engineer />;
    case 'marketing':
      return <Marketing />;
    case 'sales':
      return <Sales />;
    default:
      return null;
    // code block
  }
};

export default JobRole;
